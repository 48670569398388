import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {};

const Terms = (props: Props) => {
	return (
		<>
			<Helmet>
				<title>Terms of Service</title>
			</Helmet>
			<div className="pt-20 mb-8 font-regular indent-4 terms-text">
				<div className="mx-auto container">
					<h1 className="mb-8 text-6xl font-title font-bold text-center">Terms of Service</h1>
					<p>Welcome to ThetaWave! These terms of service ("Terms") govern your use of our services, including mathematical processing of queries in games ("Services"). By accessing or using our Services, you agree to be bound by these Terms. If you disagree with any part of these Terms, then you may not access or use our Services.</p>
					<br/>
					<ol>
						<li>
							<p>
								<strong>Your use of our Services</strong><br/>
								You may use our Services only for lawful purposes and in accordance with these Terms. You agree not to use our Services:<br/>
								● In any way that violates any applicable law or regulation.<br/>
								● In any way that infringes on the rights of others.<br/>
								● To transmit, or procure the sending of, any advertising or promotional material without our prior written consent.
							</p>
						</li>
						<li>
							<p>
								<strong>Account Information and Security.</strong>
								<ol>
									<li>In order to access certain features of the Services, you may be required to create an account. You agree to provide accurate and complete information when creating an account and to keep your account information up-to-date.</li>
									<li>You are solely responsible for maintaining the confidentiality of your account login credentials, including your username and password, and for all activities that occur under your account. You agree to immediately notify us of any unauthorized use or suspected unauthorized use of your account or any other breach of security.</li>
									<li>We will not be liable for any loss or damage arising from your failure to comply with this section, and you may be held liable for any losses incurred by us or another party due to someone else using your account or password.</li>
								</ol>
							</p>
						</li>
						<li>
							<p>
								<strong>Using the Service</strong><br/>
								Our Services are intended to assist users in mathematical processing of queries in games. However, the service is not designed to provide detailed explanations of the underlying mathematics or concepts. It focuses more on practical learning. It can be beneficial for anyone interested in Game Theory Optimal (GTO) and enhancing their skills in brain-teasing games, ranging from risk-averse beginners to advanced intellectuals.
							</p>
						</li>
						<li>
							<p>
								<strong>Intellectual Property</strong><br/>
								All content included in our Services, such as text, graphics, logos, images, and software, is the property of ThetaWave or our content suppliers and is protected by applicable intellectual property laws. You may not use, reproduce, distribute, modify, or create derivative works of any content without our express written permission.
							</p>
						</li>
						<li>
							<p>
								<strong>Monitoring of Service and User Content</strong><br/>
								We reserve the right, but do not have an obligation, to monitor any activity and content associated with the Service. We may investigate any reported violation of these Terms of Service or complaints relating to the Service, and may take any action that we deem appropriate, including issuing warnings, suspending or terminating your access to the Service, and/or removing any content from the Service.<br/>
								You acknowledge that we have no obligation to monitor your access to or use of the Service but have the right to do so for the purpose of operating the Service, ensuring your compliance with these Terms of Service, or complying with applicable law or the order or requirement of a court, administrative agency, or other governmental body.<br/>
								We reserve the right to disclose any information necessary to satisfy any applicable law, regulation, legal process, or governmental request, or to edit, refuse to post, or remove any information or materials, in whole or in part, at our sole discretion.
							</p>
						</li>
						<li>
							<p>
								<strong>Payment</strong><br/>
								Payment terms for our Services are specified in the agreement between you and Theta Wave. Please note that payment is made in cryptocurrency. By choosing to make a payment in cryptocurrency, you acknowledge and understand that you are assuming all associated risks and responsibilities related to the use and transaction of cryptocurrency, including but not limited to market fluctuations, transaction fees, security risks, and regulatory compliance. ThetaWave shall not be held liable for any loss, damages, or liabilities arising from your use of cryptocurrency for payment.<br/>
								Please ensure that you have a good understanding of cryptocurrency and the necessary knowledge to safely handle and transact in cryptocurrency before making any payment. Theta Wave recommends conducting your own research and seeking professional advice if needed.<br/>
								If you have any questions or concerns regarding cryptocurrency payments, please contact us at sales@theta-wave.io
							</p>
						</li>
						<li>
							<p>
								<strong>Third-Party Advertising</strong><br/>
								Our service may include third-party advertising. We do not control the content of these advertisements or the practices of the companies providing them. If you click on an advertisement, you will be directed to a third-party website, and your interaction with that website will be governed by its own terms and policies. We are not responsible for any damages or losses caused bythird-party advertisements or websites.
							</p>
						</li>
						<li>
							<p>
								<strong>Availability of the Services; Warranty Disclaimer</strong><br/>
								Our services are provided on an "as is" and "as available" basis. ThetaWave does not warrant that the services will be uninterrupted, error-free, or free of viruses or other harmful components. We do not make any warranties, express or implied, regarding the use or results of the services, including, without limitation, any implied warranties of merchantability or fitness for a particular purpose.
							</p>
						</li>
						<li>
							<p>
								<strong>Limitations; Waiver of Liability</strong><br/>
								To the fullest extent permitted by applicable law, ThetaWave, its officers, directors, employees, agents, licensors, suppliers, and any third-party partners shall not be liable to you or any third party for any direct, indirect, incidental, special, consequential, or punitive damages, including without limitation, damages for loss of profits, goodwill, use, data, or other intangible losses, arising out of or in connection with your use of the Service, whether based on warranty, contract, tort, or any other legal theory, and whether or not ThetaWave has been advised of the possibility of such damages.<br/>
								ThetaWave assumes no responsibility for the timeliness, deletion, failure to store, or failure to deliver any content or material. ThetaWave shall not be responsible for any harm resulting from downloading or accessing any information or material on the Internet using the Service.<br/>
								You acknowledge that ThetaWave has no control over, and no duty to take any action regarding, any third party's access to or use of User Content or other content or materials.<br/>
								You agree to indemnify and hold harmless ThetaWave, its affiliates, and their respective officers, directors, employees, agents, licensors, and suppliers from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising from your use of the Service, your User Content, or your violation of these Terms of Service.<br/>
								You acknowledge and agree that you have not relied on any representations or warranties made by ThetaWave, except as expressly set forth in these Terms of Service.<br/>
								Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations may not apply to you.
							</p>
						</li>
						<li>
							<p>
								<strong>Agreement to Arbitrate and Class Action Waiver:</strong><br/>
								Any claim, dispute, or controversy arising out of or in connection with the Services, including but not limited to the use of the Services or the interpretation, validity, enforceability, or breach of these Terms of Service, shall be resolved through arbitration. The arbitration shall be conducted by a single arbitrator in accordance with the rules of the European Arbitration Association. The arbitrator's decision and award shall be final and binding, and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.<br/>
								You and the company agree that any arbitration shall be conducted on an individual basis and not on a class, consolidated, or representative basis. You and the company further agree that the arbitrator may not consolidate proceedings or claims or otherwise preside over any form of a representative or class proceeding. You acknowledge that, by agreeing to these Terms of Service, you and the company are waiving the right to a trial by jury or to participate in a class action, collective action, private attorney general action, or other representative proceeding of any kind.
							</p>
						</li>
						<li>
							<p>
								<strong>Applicable Law:</strong><br/>
								These Terms of Service and any legal disputes arising out of or related to your use of our services shall be governed by and construed in accordance with the laws of the European Union, without giving effect to its conflict of laws principles. Any legal disputes not subject to arbitration shall be brought in the courts of the European Union. If you are located outside of the European Union, you agree that your use of our services isat your own risk and you are solely responsible for compliance with any applicable laws in your jurisdiction.
							</p>
						</li>
						<li>
							<p>
								<strong>Venue for Legal Disputes not Subject to Arbitration:</strong><br/>
								For customers within Europe, including those outside of Cyprus, any legal disputes arising out of or in connection with these Terms of Service or the use of our services shall be subject to the exclusive jurisdiction of the courts of Cyprus.<br/>
								For customers outside of Europe, any legal disputes arising out of or in connection with these Terms of Service or the use of our services shall be subject to the exclusive jurisdiction of the courts located in the country where the services are being provided.
							</p>
						</li>
						<li>
							<p>
								<strong>Assignment:</strong><br/>
								We may assign or transfer these Terms, in whole or in part, without notice to you. You may not assign or transfer these Terms, or any of your rights or obligations under these Terms, without our prior written consent, which may be withheld at our sole discretion. Any attempted assignment or transfer without our consent is void.
							</p>
							<br/>
						</li>
						<li>
							<p>
								<strong>Entire Agreement</strong><br/>
								These Terms of Service and any other policies or agreements referenced herein constitute the entire agreement between you and ThetaWave and supersede all prior or contemporaneous communications and proposals, whether oral or written, between you and us with respect to the subject matter of these Terms of Service. Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
							</p>
						</li>
						<li>
							<p>
								<strong>Language of the Terms</strong><br/>
								The language of these Terms of Service is English. In case of any discrepancy between the translated versions and the English version, the English version shall prevail.
							</p>
						</li>
						<li>
							<p>
								<strong>No Waiver</strong><br/>
								If we fail to enforce any of our rights under these Terms, it does not mean that we are waiving our right to do so in the future. If we explicitly waive a provision of these Terms, it does not mean that the provision is waived permanently. In case we waive a default or breach by you, we do not waive any subsequent default or breach by you.<br/>
								If you have any questions or concerns about these Terms of Service, please contact us at sales@theta-wave.io
							</p>
						</li>
					</ol>
				</div>
			</div>
		</>
	);
};

export default Terms;
